/* SubscriptionBenefits.css */

.subscription-benefits {
  padding: 60px 30px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.subscription-benefits h2 {
  font-size: 2.8rem;
  color: #2c3e50;
  margin-bottom: 30px;
}

.subscription-benefits ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 50px;
}

.benefit-item {
  font-size: 1.4rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
}

.benefit-item-even {
  background-color: #ffffff;
}

.benefit-item-odd {
  background-color: #f0f8ff;
}

.benefit-icon {
  margin-right: 10px;
  color: #ffcc29; /* Changed to match the yellow theme */
  font-size: 1.8rem;
}

.cta-btn {
  padding: 16px 40px;
  background-color: #ffcc29; /* Changed to match the yellowish theme */
  color: #2c3e50; /* Dark text for contrast */
  border: 2px solid #e6b800; /* Adjusted border color */
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.3rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.cta-btn:hover {
  background-color: #e6b800; /* Darker yellow */
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .subscription-benefits h2 {
    font-size: 2.2rem;
  }

  .benefit-item {
    font-size: 1.2rem;
  }

  .cta-btn {
    padding: 14px 35px;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .subscription-benefits {
    padding: 40px 20px;
  }

  .subscription-benefits h2 {
    font-size: 1.8rem;
  }

  .benefit-item {
    font-size: 1rem;
  }

  .cta-btn {
    padding: 12px 30px;
    font-size: 1.1rem;
  }
}
