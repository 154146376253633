.about-us {
  font-family: 'Poppins', sans-serif;
}

.hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
}

.hero-section h1{
color: white;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 12px;
}

.hero-overlay h1 {
  font-size: 2.8rem;
  margin-bottom: 10px;
}

.hero-overlay p {
  font-size: 1.3rem;
}

.mission-section {
  padding: 60px 20px;
  background-color: #f0f8ff;
  text-align: center;
}

.mission-section .container {
  max-width: 1000px;
  margin: 0 auto;
}

.mission-section h2 {
  font-size: 2.6rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.mission-section p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #2c3e50;
}

.values-section {
  padding: 60px 20px;
  background-color: #ffffff;
}

.values-section .container {
  max-width: 1000px;
  margin: 0 auto;
}

.values-section h2 {
  font-size: 2.4rem;
  color: #2c3e50;
  margin-bottom: 30px;
}

.values-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.value-item {
  width: 280px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.value-item h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #ffcc29;
}

.value-item p {
  font-size: 1.1rem;
  color: #333;
}

.achievements-section {
  padding: 60px 20px;
  background-color: #f0f8ff;
}

.achievements-section .container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.achievements-section h2 {
  font-size: 2.4rem;
  color: #2c3e50;
  margin-bottom: 30px;
}

.achievements-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.achievement-item {
  width: 280px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.achievement-item h3 {
  font-size: 1.8rem;
  color: #ffcc29;
  margin-bottom: 10px;
}

.achievement-item p {
  font-size: 1.1rem;
  color: #333;
}

@media (max-width: 768px) {
  .hero-section {
    height: 40vh;
  }

  .hero-overlay h1 {
    font-size: 2.4rem;
  }

  .hero-overlay p {
    font-size: 1.1rem;
  }

  .value-item, .achievement-item {
    width: 100%;
  }

  .mission-section h2, .achievements-section h2 {
    font-size: 2.2rem;
  }
}
