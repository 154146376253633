
.testimonials {
  padding: 60px 30px;
  background-color: #fdfdfd;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.testimonials h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
}

.testimonial-list {
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.testimonial {
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

blockquote {
  font-style: italic;
  font-size: 1.3rem;
  color: #555;
  margin-bottom: 15px;
}

footer {
  font-size: 1rem;
  color: #777;
}

@media (min-width: 768px) {
  .testimonial-list {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
