/* FAQ.css */

.faq {
  padding: 60px 30px;
  background-color: #fdfdfd;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.faq h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
  font-weight: bold;
}

.faq-list {
  max-width: 900px;
  margin: 0 auto;
}

.faq-item {
  background: linear-gradient(135deg, #ffffff, #f7f7f7);
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
}

.faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.faq-item h3 {
  font-size: 1.8rem;
  color: #444;
  margin-bottom: 15px;
}

.faq-item p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
}

@media (min-width: 768px) {
  .faq-item {
    padding: 25px;
  }

  .faq-item h3 {
    font-size: 2rem;
  }

  .faq-item p {
    font-size: 1.3rem;
  }
}