.hero-section {
  position: relative;
  width: 100%;
  height: 85vh; 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider {
  width: 100%;
  height: 100%;
}

.slick-slide img {
  width: 100%;
  height: 100%; 
  object-fit: cover;
  filter: brightness(0.6);
  transition: transform 0.5s;
}

.slick-slide img:hover {
  transform: scale(1.05);
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  width: 90%;
  max-width: 700px;
  animation: fadeIn 1.5s ease;
}

.hero-title {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 25px;
  color: #ffcc29;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.hero-description {
  font-size: 1.6rem;
  margin-bottom: 35px;
  line-height: 1.5;
  color: #f1f1f1;
}

.cta-btn {
  padding: 15px 45px;
  background-color: #ffcc29;
  color: #1b1b2f;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  transition: transform 0.3s, background-color 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

.cta-btn:hover {
  background-color: #e0b020;
  transform: translateY(-3px);
}

.pricing-info {
  font-size: 1rem;
  margin-top: 20px;
  color: #f1f1f1;
  opacity: 0.85;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 100vh; 
  }

  .hero-overlay {
    padding: 25px;
    max-width: 90%; 
  }

  .hero-title {
    font-size: 2.8rem;
    margin-bottom: 20px;
  }

  .hero-description {
    font-size: 1.3rem;
    margin-bottom: 25px;
  }

  .cta-btn {
    padding: 12px 35px;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 100vh; 
  }

  .hero-overlay {
    background-color: transparent; 
    padding: 0;
    max-width: 100%; 
  }

  .hero-title {
    font-size: 2.4rem;
    margin-bottom: 15px;
  }

  .hero-description {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  .cta-btn {
    padding: 10px 25px; 
    font-size: 1rem;
  }

  .pricing-info {
    font-size: 0.85rem;
  }
}
