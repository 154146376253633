.unsubscribe-refund-page {
    padding: 30px 20px;
    width: 100%;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    box-sizing: border-box;
    overflow-x: hidden; 
  }
  
  .unsubscribe-refund-page h1 {
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 20px;
    text-align: left; 
    border-left: 5px solid #ff4500;
    padding-left: 10px;
  }
  
  .unsubscribe-refund-page h2 {
    font-size: 1.8rem;
    color: #444;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    text-align: left; 
  }
  
  .unsubscribe-refund-page h3 {
    font-size: 1.5rem;
    color: #666;
    margin-top: 25px;
    text-align: left; 
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
  }
  
  .unsubscribe-refund-page p {
    font-size: 1rem;
    line-height: 1.8;
    margin-bottom: 15px;
    text-align: left; 
  }
  
  .unsubscribe-refund-page a {
    color: #ff4500;
    text-decoration: none;
    font-weight: bold;
  }
  
  .unsubscribe-refund-page a:hover {
    text-decoration: underline;
  }
  
  /* Lists for organized content */
  .unsubscribe-refund-page ul {
    padding-left: 20px; /* Proper indentation for lists */
    list-style-type: disc;
    margin-bottom: 15px;
  }
  
  .unsubscribe-refund-page ul li {
    font-size: 1rem;
    margin-bottom: 8px;
    line-height: 1.6;
    text-align: left; /* List items left-aligned */
  }
  
  /* Ensure full viewport width and prevent horizontal scrolling */
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .unsubscribe-refund-page {
      padding: 20px;
    }
  
    .unsubscribe-refund-page h1 {
      font-size: 2rem;
    }
  
    .unsubscribe-refund-page h2 {
      font-size: 1.5rem;
    }
  
    .unsubscribe-refund-page h3 {
      font-size: 1.3rem;
    }
  
    .unsubscribe-refund-page p {
      font-size: 0.95rem;
    }
  
    .unsubscribe-refund-page ul {
      padding-left: 15px;
    }
  }
  