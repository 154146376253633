.video-gallery {
  padding: 50px 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.video-gallery h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

.video-category {
  margin-bottom: 40px;
}

.video-category h3 {
  font-size: 1.8rem;
  color: #333; /* Updated to match a more neutral tone */
  margin-bottom: 20px;
}

.video-gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default 3 columns */
  gap: 20px;
}

.video-item {
  width: 100%; /* Adjusted for responsive grid layout */
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .video-gallery-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
  }
}

@media (max-width: 480px) {
  .video-gallery-grid {
    grid-template-columns: 1fr; /* 1 column on smaller screens */
  }

  .video-gallery h2 {
    font-size: 1.8rem;
  }

  .video-category h3 {
    font-size: 1.5rem;
  }
}
