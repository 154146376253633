/* Footer.css */

.footer {
  background-color: #1c1c1c; 
  color: #ffffff;
  padding: 50px 0;
  text-align: center;
  position: relative; 
  border-top: 4px solid #ffcc29;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; 
}

.footer-logo {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 25px;
}

.footer-description {
  font-size: 1rem;
  margin-bottom: 20px;
  opacity: 0.9; 
}

.footer-description a {
  color: #ffcc29;
  text-decoration: none;
}

.footer-description a:hover {
  color: #e6b800; 
}

.footer-links {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.footer-links a {
  color: #ffcc29; 
  margin: 0 10px;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s; 
}

.footer-links a:hover {
  color: #e6b800; 
  text-decoration: underline;
}

.footer-content p {
  margin: 5px 0; 
  font-size: 1rem;
}

.footer-bottom {
  margin-top: 30px;
  font-size: 0.9rem; 
  opacity: 0.8; 
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 15px;
}