.sign-in-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 100%;
    padding: 40px 20px;
    font-family: 'Roboto', sans-serif;
    background-color: #f0f0f0;
    box-sizing: border-box;
    text-align: center;
}

.sign-in-page form {
    width: 100%;
    max-width: 450px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 35px 25px;
    box-sizing: border-box;
}

h1 {
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 25px;
}

.intro-text {
    font-size: 1rem;
    color: #666;
    margin-bottom: 25px;
}

.input-group {
    margin-bottom: 20px;
}

.input-group label {
    display: block;
    font-size: 1rem;
    color: #444;
    margin-bottom: 8px;
}

.input-group input {
    width: 100%;
    padding: 14px;
    font-size: 1rem;
    border: 1px solid #bbb;
    border-radius: 8px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.input-group input:focus {
    border-color: #ffa726;
    outline: none;
}

.sign-in-btn {
    width: 100%;
    padding: 14px;
    background-color: #ffa726;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    text-align: center;
    margin-bottom: 20px;
    transition: background-color 0.3s;
}

.sign-in-btn:hover {
    background-color: #ffa726;
}

.success-message, .error-message {
    text-align: center;
    margin-top: 12px;
    font-size: 1rem;
    font-weight: bold;
}

.success-message {
    color: #28a745;
}

.error-message {
    color: #ffa726;
}

.extra-info {
    text-align: center;
    margin-top: 25px;
}

.extra-info h2 {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 10px;
}

.subscription-link {
    color: #ffa726;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
}

.subscription-link:hover {
    text-decoration: underline;
}

.terms-note {
    text-align: center;
    margin-top: 35px;
}

.terms-note h2 {
    font-size: 1.4rem;
    margin-bottom: 15px;
}

.terms-note ul {
    list-style-type: none;
    padding: 0;
}

.terms-note ul li {
    margin-bottom: 12px;
}

.terms-note a {
    color: #ffa726;
    text-decoration: none;
    font-weight: bold;
}

.terms-note a:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sign-in-page {
        padding: 20px;
    }

    h1 {
        font-size: 2rem;
    }

    .intro-text {
        font-size: 0.95rem;
    }

    .sign-in-btn {
        padding: 12px;
        font-size: 1rem;
    }

    .terms-note h2, .extra-info h2 {
        font-size: 1.3rem;
    }

    .terms-note ul li {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.8rem;
    }

    .intro-text {
        font-size: 0.85rem;
    }

    .sign-in-btn {
        padding: 10px;
        font-size: 0.95rem;
    }

    .terms-note ul li {
        font-size: 0.85rem;
    }
}