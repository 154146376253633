.cookie-policy-page {
    padding: 40px 20px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #f4f6f9;
    box-sizing: border-box;
    overflow-x: hidden;
}

.cookie-policy-page h1 {
    font-size: 2.7rem;
    color: #222;
    margin-bottom: 25px;
    text-align: left;
    border-left: 6px solid #ffa726;
    padding-left: 12px;
    background: linear-gradient(90deg, #ffa726 0%, #ffa726 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cookie-policy-page h2 {
    font-size: 2rem;
    color: #444;
    margin-top: 35px;
    margin-bottom: 18px;
    border-bottom: 2px solid #ffc107;
    padding-bottom: 12px;
    text-align: left;
}

.cookie-policy-page p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: left;
}

.cookie-policy-page a {
    color: #ff9800;
    text-decoration: underline;
    font-weight: 500;
}

.cookie-policy-page a:hover {
    text-decoration: none;
    color: #e65100;
}

.cookie-policy-page ul {
    padding-left: 25px;
    list-style-type: disc;
    margin-bottom: 20px;
}

.cookie-policy-page ul li {
    font-size: 1.1rem;
    margin-bottom: 10px;
    line-height: 1.6;
    text-align: left;
    color: #333;
}

.cookie-policy-page ul li::marker {
    color: #ff9800;
}

@media (max-width: 768px) {
    .cookie-policy-page {
        padding: 30px 15px;
    }

    .cookie-policy-page h1 {
        font-size: 2.3rem;
    }

    .cookie-policy-page h2 {
        font-size: 1.7rem;
    }

    .cookie-policy-page p {
        font-size: 1rem;
    }

    .cookie-policy-page ul {
        padding-left: 20px;
    }
}
