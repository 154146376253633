.terms-page {
  padding: 40px 25px;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f4f6f9; /* Light background for better readability */
  box-sizing: border-box; /* Ensures padding is included in width calculation */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.terms-page h1 {
  font-size: 2.7rem;
  color: #222;
  margin-bottom: 25px;
  text-align: left;
  border-left: 6px solid #ffa726; /* Visual indicator with a warm accent */
  padding-left: 12px;
  background: linear-gradient(90deg, #ffa726 0%, #ffa726 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Modern gradient text */
}

.terms-page h2 {
  font-size: 2rem;
  color: #444;
  margin-top: 35px;
  margin-bottom: 18px;
  border-bottom: 2px solid #ffc107;
  padding-bottom: 12px;
  text-align: left;
}

.terms-page p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: left;
}

.terms-page a {
  color: #ff9800;
  text-decoration: underline;
  font-weight: 500;
}

.terms-page a:hover {
  text-decoration: none;
  color: #e65100;
}

/* Subsections styling */
.terms-page h3 {
  font-size: 1.7rem;
  color: #666;
  margin-top: 30px;
  text-align: left;
  padding-bottom: 6px;
  border-bottom: 1px solid #ddd;
}

/* Lists for organized content */
.terms-page ul {
  padding-left: 25px;
  list-style-type: disc;
  margin-bottom: 20px;
}

.terms-page ul li {
  font-size: 1.1rem;
  margin-bottom: 10px;
  line-height: 1.6;
}

.terms-page ul li::marker {
  color: #ff9800; /* Custom marker color to match theme */
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .terms-page {
    padding: 30px 15px;
  }

  .terms-page h1 {
    font-size: 2.3rem;
  }

  .terms-page h2 {
    font-size: 1.7rem;
  }

  .terms-page h3 {
    font-size: 1.5rem;
  }

  .terms-page p {
    font-size: 1rem;
  }

  .terms-page ul {
    padding-left: 20px;
  }
}
