/* ContactUs.css */

.contact-us {
  padding: 60px 30px;
  background-color: #fdfdfd;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.contact-us h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.contact-us p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input,
.contact-form textarea {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #ffcc29;
  outline: none;
}

.contact-form textarea {
  resize: vertical;
  min-height: 150px;
}

.submit-btn {
  padding: 15px 30px;
  background-color: #ffcc29;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.submit-btn:hover {
  background-color: #e6b800;
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .contact-us h2 {
    font-size: 2.2rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.9rem;
  }

  .submit-btn {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-us {
    padding: 40px 20px;
  }

  .contact-us h2 {
    font-size: 1.8rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.85rem;
  }

  .submit-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
.success-message {
  margin-top: 20px;
  font-size: 1rem;
  color: #4caf50; /* Green color for success */
}
