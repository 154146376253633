/* GameCard.css */

.game-card {
  position: relative;
  width: 100%;
  height: 320px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-end;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.game-card:hover {
  transform: scale(1.08);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
}

.game-card-overlay {
  width: 100%;
  background: linear-gradient(60deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
  color: #fbfbfb;
  padding: 25px;
  text-align: center;
  backdrop-filter: blur(5px);
}

.game-card-title {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  color: #ffffff; /* Ensuring title color is white */
}

.game-card-btn {
  padding: 12px 25px;
  background-color: #f39c12; /* Keeping yellow color for button */
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

.game-card-btn:hover {
  background-color: #e67e22;
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .game-card {
    height: 280px;
  }

  .game-card-title {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  .game-card-btn {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .game-card {
    height: 240px;
  }

  .game-card-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .game-card-btn {
    padding: 8px 18px;
    font-size: 0.9rem;
  }
}