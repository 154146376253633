.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: #1b1b2f;
  color: #e0e0e0;
  position: relative;
  z-index: 100;
}

.logo {
  font-size: 1.7rem;
  font-weight: bold;
  color: #f0a500;
  text-decoration: none;
}

.nav-links {
  display: flex;
  gap: 25px;
  align-items: center;
}

.nav-links a, .subscribe-btn {
  color: #e0e0e0;
  text-decoration: none;
  font-size: 1.1rem;
}

.nav-links a:hover, .subscribe-btn:hover {
  color: #f0a500;
}

.subscribe-btn {
  background-color: #f0a500;
  color: #1b1b2f;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-btn:hover {
  background-color: #d88c00;
}

/* Language Selector Styling */
.lang-selector {
  position: relative;
}

.lang-selector select {
  background-color: #1b1b2f;
  color: #e0e0e0;
  border: 1px solid #f0a500;
  font-size: 1rem;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.lang-selector select:hover {
  background-color: #f0a500;
  color: #1b1b2f;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.bar {
  height: 3px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-links {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    background-color: #1b1b2f;
    padding: 20px;
    display: none;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links a {
    color: #f0a500;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .nav-links a:hover {
    color: #ffffff;
  }

  .subscribe-btn {
    background-color: #f0a500;
    color: #1b1b2f;
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }

  .subscribe-btn:hover {
    background-color: #d88c00;
  }

  .lang-selector select {
    background-color: #f0a500;
    color: #1b1b2f;
    font-size: 1.2rem;
    padding: 8px 15px;
    border-radius: 5px;
  }
}
